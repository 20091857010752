.wrapper_loader {
	width: 100%;
	height: 45rem;
	background-color: rgba(0, 0, 0, 0.05);
	position: fixed;
	top: 0%;
	z-index: 9999;
}
.loader {
	position: absolute;
	top: 40%;
	left: 43%;
	border: 16px solid rgb(245, 245, 245);
	border-radius: 50%;
	border-top: 16px solid #3498db;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite; /* Safari */
	animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
