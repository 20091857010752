/*
=========================================
=========================================

   Boutique | Ecommerce bootstrap template

=========================================
========================================= */
/*
*
* ==========================================
* GENERAL
* ==========================================
*
*/
.text-small {
	font-size: 0.85rem !important;
}

.text-gray {
	color: #aaa !important;
}

strong {
	font-weight: 700;
}

button:focus,
button:active {
	outline: none;
}

/*
*
* ==========================================
* NAVBAR
* ==========================================
*
*/
.navbar {
	position: relative;
}

.navbar .dropdown-menu {
	border-top: 2px solid #dcb14a;
}

.navbar .dropdown-menu::before {
	content: '';
	display: block;
	border-left: 0.5rem solid transparent;
	border-right: 0.5rem solid transparent;
	border-bottom: 0.5rem solid #dcb14a;
	position: absolute;
	top: -0.5rem;
	left: 1rem;
}

@media (min-width: 992px) {
	.navbar-brand {
		position: absolute;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
}

.navbar .dropdown-menu {
	visibility: hidden;
	opacity: 0;
	-webkit-transform: translateY(0.5rem);
	transform: translateY(0.5rem);
	transition: 0.2s ease all;
}

@media (min-width: 992px) {
	.navbar .dropdown-menu {
		display: block;
	}
}

@media (max-width: 991.98px) {
	.navbar .dropdown-menu.always-animated {
		display: block;
	}
}

.navbar .dropdown-menu.show {
	display: block;
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateY(0px);
	transform: translateY(0px);
	transition: 0.2s ease all;
}

.megamenu {
	position: static;
}

.megamenu .dropdown-menu {
	width: 100%;
	z-index: 999;
}

/*
*
* ==========================================
* HERO
* ==========================================
*
*/
.hero {
	min-height: 30rem;
}

/*
*
* ==========================================
* CATEGORY
* ==========================================
*
*/
.category-item {
	display: block;
	position: relative;
	color: #343a40;
	transition: all 0.3s;
}

.category-item img {
	transition: all 0.3s;
}

.category-item-title {
	display: inline-block;
	padding: 0.5rem 1rem;
	background: #fff;
	text-transform: uppercase;
	letter-spacing: 0.07em;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.07);
	font-size: 0.8rem;
}

.category-item:hover img {
	opacity: 0.7;
}

/*
*
* ==========================================
* PRODUCT
* ==========================================
*
*/
.product img {
	-webkit-filter: grayscale(40%);
	filter: grayscale(40%);
	transition: all 0.3s;
}

.product-overlay {
	width: 100%;
	position: absolute;
	left: 0;
	bottom: 0;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: end;
	align-items: flex-end;
	-ms-flex-pack: center;
	justify-content: center;
	padding: 1rem 0;
	opacity: 0;
	transition: all 0.3s;
}

.product-overlay ul li {
	transition: all 0.3s;
}

.product-overlay ul li:first-of-type {
	-webkit-transform: translateX(-10px);
	transform: translateX(-10px);
	opacity: 0;
}

.product-overlay ul li:last-of-type {
	-webkit-transform: translateX(10px);
	transform: translateX(10px);
	opacity: 0;
}

.product:hover img {
	opacity: 0.3;
}

.product:hover .product-overlay {
	opacity: 1;
}

.product:hover .product-overlay li {
	opacity: 1;
	-webkit-transform: none;
	transform: none;
}

.product .badge {
	position: absolute;
	top: 1rem;
	right: 1rem;
	z-index: 999;
}

/*
*
* ==========================================
* PRODUCT MODAL
* ==========================================
*
*/
.product-view {
	min-height: 20rem;
}

.quantity {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
}

.quantity input {
	width: 2rem;
	text-align: center;
}

.quantity button {
	background: none;
	border: none;
	width: 1rem;
	outline: none;
}

/*
*
* ==========================================
* FOOTER
* ==========================================
*
*/
.footer-link {
	font-size: 0.85rem;
	position: relative;
	color: #999;
	transition: all 0.3s;
}

.footer-link:hover,
.footer-link:focus {
	padding-left: 0.6rem;
	color: #fff;
	text-decoration: none;
}

.footer-link:hover::before,
.footer-link:focus::before {
	opacity: 1;
}

.footer-link::before {
	content: '\f0da';
	display: inline-block;
	font-weight: 900;
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #fff;
	transition: all 0.3s;
	opacity: 0;
}

/*
* ===================================================
*    Custom Bootstrapious Utils
* ===================================================
*/
/*
   Text utils
   ------------------------
*/
.text-uppercase {
	text-transform: uppercase;
	letter-spacing: 0.1em;
}

.text-sm {
	font-size: 0.875rem;
}

.text-base {
	font-size: 1rem;
}

.text-lg {
	font-size: 1.25rem;
}

.text-xs {
	font-size: 0.65rem !important;
}

.text-shadow {
	text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.text-shadow-primary {
	text-shadow: 2px 2px 2px rgba(220, 177, 74, 0.1);
}

.text-decoration-underline {
	text-decoration: underline;
}

.text-inherit {
	color: inherit;
}

/*
    Rounded corners - lg, sm
   ------------------------
*/
.rounded-lg {
	border-radius: 0;
}

.rounded-sm {
	border-radius: 0;
}

.rounded-xl {
	border-radius: 10rem;
}

/*
    Responsive borders - border-[breakpoint]
   ------------------------
*/
.border {
	border: 1px solid #dee2e6 !important;
}

@media (min-width: 576px) {
	.border-sm {
		border: 1px solid #dee2e6 !important;
	}
}

@media (min-width: 768px) {
	.border-md {
		border: 1px solid #dee2e6 !important;
	}
}

@media (min-width: 992px) {
	.border-lg {
		border: 1px solid #dee2e6 !important;
	}
}

@media (min-width: 1200px) {
	.border-xl {
		border: 1px solid #dee2e6 !important;
	}
}

/*
    Opacity helpers - .opacity-[1-9]
    ------------------------
*/
.opacity-1 {
	opacity: 0.1;
}

.opacity-2 {
	opacity: 0.2;
}

.opacity-3 {
	opacity: 0.3;
}

.opacity-4 {
	opacity: 0.4;
}

.opacity-5 {
	opacity: 0.5;
}

.opacity-6 {
	opacity: 0.6;
}

.opacity-7 {
	opacity: 0.7;
}

.opacity-8 {
	opacity: 0.8;
}

.opacity-9 {
	opacity: 0.9;
}

.opacity-10 {
	opacity: 1;
}

/*
    Z-index helpers - .z-index-[10-50]
    ------------------------
*/
.z-index-10 {
	z-index: 10;
}

.z-index-20 {
	z-index: 20;
}

.z-index-30 {
	z-index: 30;
}

.z-index-40 {
	z-index: 40;
}

.z-index-50 {
	z-index: 50;
}

/*
     Letter spacing helpers - .letter-spacing-[0-5]
    ------------------------
*/
.letter-spacing-0 {
	letter-spacing: 0 !important;
}

.letter-spacing-1 {
	letter-spacing: 0.1em;
}

.letter-spacing-2 {
	letter-spacing: 0.2em;
}

.letter-spacing-3 {
	letter-spacing: 0.3em;
}

.letter-spacing-4 {
	letter-spacing: 0.4em;
}

.letter-spacing-5 {
	letter-spacing: 0.5em;
}

/*
     Colours and backgrounds
    ------------------------
*/
.text-hover-primary {
	transition: all 0.2s ease-in-out;
}

.text-hover-primary:focus,
.text-hover-primary:hover {
	color: #dcb14a !important;
}

a.text-hover-primary {
	transition: all 0.2s ease-in-out;
}

a.text-hover-primary:focus,
a.text-hover-primary:hover {
	color: #dcb14a !important;
}

.bg-primary-light {
	background-color: #fbf6e9;
}

.border-primary {
	border-color: #dcb14a !important;
}

.text-hover-secondary {
	transition: all 0.2s ease-in-out;
}

.text-hover-secondary:focus,
.text-hover-secondary:hover {
	color: #6c757d !important;
}

a.text-hover-secondary {
	transition: all 0.2s ease-in-out;
}

a.text-hover-secondary:focus,
a.text-hover-secondary:hover {
	color: #6c757d !important;
}

.bg-secondary-light {
	background-color: #edeeef;
}

.border-secondary {
	border-color: #6c757d !important;
}

.text-hover-success {
	transition: all 0.2s ease-in-out;
}

.text-hover-success:focus,
.text-hover-success:hover {
	color: #28a745 !important;
}

a.text-hover-success {
	transition: all 0.2s ease-in-out;
}

a.text-hover-success:focus,
a.text-hover-success:hover {
	color: #28a745 !important;
}

.bg-success-light {
	background-color: #e5f4e9;
}

.border-success {
	border-color: #28a745 !important;
}

.text-hover-info {
	transition: all 0.2s ease-in-out;
}

.text-hover-info:focus,
.text-hover-info:hover {
	color: #17a2b8 !important;
}

a.text-hover-info {
	transition: all 0.2s ease-in-out;
}

a.text-hover-info:focus,
a.text-hover-info:hover {
	color: #17a2b8 !important;
}

.bg-info-light {
	background-color: #e3f4f6;
}

.border-info {
	border-color: #17a2b8 !important;
}

.text-hover-warning {
	transition: all 0.2s ease-in-out;
}

.text-hover-warning:focus,
.text-hover-warning:hover {
	color: #ffc107 !important;
}

a.text-hover-warning {
	transition: all 0.2s ease-in-out;
}

a.text-hover-warning:focus,
a.text-hover-warning:hover {
	color: #ffc107 !important;
}

.bg-warning-light {
	background-color: #fff8e1;
}

.border-warning {
	border-color: #ffc107 !important;
}

.text-hover-danger {
	transition: all 0.2s ease-in-out;
}

.text-hover-danger:focus,
.text-hover-danger:hover {
	color: #dc3545 !important;
}

a.text-hover-danger {
	transition: all 0.2s ease-in-out;
}

a.text-hover-danger:focus,
a.text-hover-danger:hover {
	color: #dc3545 !important;
}

.bg-danger-light {
	background-color: #fbe7e9;
}

.border-danger {
	border-color: #dc3545 !important;
}

.text-hover-light {
	transition: all 0.2s ease-in-out;
}

.text-hover-light:focus,
.text-hover-light:hover {
	color: #f8f9fa !important;
}

a.text-hover-light {
	transition: all 0.2s ease-in-out;
}

a.text-hover-light:focus,
a.text-hover-light:hover {
	color: #f8f9fa !important;
}

.bg-light-light {
	background-color: #fefefe;
}

.border-light {
	border-color: #f8f9fa !important;
}

.text-hover-dark {
	transition: all 0.2s ease-in-out;
}

.text-hover-dark:focus,
.text-hover-dark:hover {
	color: #111111 !important;
}

a.text-hover-dark {
	transition: all 0.2s ease-in-out;
}

a.text-hover-dark:focus,
a.text-hover-dark:hover {
	color: #111111 !important;
}

.bg-dark-light {
	background-color: #e2e2e2;
}

.border-dark {
	border-color: #111111 !important;
}

.bg-gray-100 {
	background: #f8f9fa !important;
}

.text-gray-100 {
	color: #f8f9fa;
}

.bg-gray-200 {
	background: #e9ecef !important;
}

.text-gray-200 {
	color: #e9ecef;
}

.bg-gray-300 {
	background: #dee2e6 !important;
}

.text-gray-300 {
	color: #dee2e6;
}

.bg-gray-400 {
	background: #ced4da !important;
}

.text-gray-400 {
	color: #ced4da;
}

.bg-gray-500 {
	background: #adb5bd !important;
}

.text-gray-500 {
	color: #adb5bd;
}

.bg-gray-600 {
	background: #6c757d !important;
}

.text-gray-600 {
	color: #6c757d;
}

.bg-gray-700 {
	background: #495057 !important;
}

.text-gray-700 {
	color: #495057;
}

.bg-gray-800 {
	background: #343a40 !important;
}

.text-gray-800 {
	color: #343a40;
}

.bg-gray-900 {
	background: #212529 !important;
}

.text-gray-900 {
	color: #212529;
}

.badge-primary-light {
	color: #dcb14a;
	background-color: #fbf6e9;
}

.badge-secondary-light {
	color: #6c757d;
	background-color: #edeeef;
}

.badge-success-light {
	color: #28a745;
	background-color: #e5f4e9;
}

.badge-info-light {
	color: #17a2b8;
	background-color: #e3f4f6;
}

.badge-warning-light {
	color: #ffc107;
	background-color: #fff8e1;
}

.badge-danger-light {
	color: #dc3545;
	background-color: #fbe7e9;
}

.badge-light-light {
	color: #f8f9fa;
	background-color: #fefefe;
}

.badge-dark-light {
	color: #111111;
	background-color: #e2e2e2;
}

/*
     Transparent overlays
    ------------------------
*/
.overlay-content {
	position: relative;
	z-index: 20;
}

@media (min-width: 576px) {
	.light-overlay-sm,
	.overlay-hover-light-sm {
		position: relative;
	}
	.light-overlay-sm::after,
	.overlay-hover-light-sm::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: ' ';
		background: #fff;
	}
}

@media (min-width: 576px) {
	.overlay-hover-light-sm::after {
		transition: opacity 0.15s linear;
		opacity: 0;
	}
}

@media (min-width: 576px) {
	.light-overlay-sm::after,
	.overlay-hover-light-sm:hover::after {
		opacity: 0.4;
	}
}

@media (min-width: 576px) {
	.light-overlay-sm-0::after,
	.overlay-hover-light-sm-0:hover::after {
		display: none;
	}
}

.dark-overlay-sm {
	position: relative;
}

@media (min-width: 576px) {
	.dark-overlay-sm::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: ' ';
		opacity: 0.4;
		background: #000;
	}
}

@media (min-width: 576px) {
	.dark-overlay-sm-0::after {
		display: none;
	}
}

@media (min-width: 768px) {
	.light-overlay-md,
	.overlay-hover-light-md {
		position: relative;
	}
	.light-overlay-md::after,
	.overlay-hover-light-md::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: ' ';
		background: #fff;
	}
}

@media (min-width: 768px) {
	.overlay-hover-light-md::after {
		transition: opacity 0.15s linear;
		opacity: 0;
	}
}

@media (min-width: 768px) {
	.light-overlay-md::after,
	.overlay-hover-light-md:hover::after {
		opacity: 0.4;
	}
}

@media (min-width: 768px) {
	.light-overlay-md-0::after,
	.overlay-hover-light-md-0:hover::after {
		display: none;
	}
}

.dark-overlay-md {
	position: relative;
}

@media (min-width: 768px) {
	.dark-overlay-md::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: ' ';
		opacity: 0.4;
		background: #000;
	}
}

@media (min-width: 768px) {
	.dark-overlay-md-0::after {
		display: none;
	}
}

@media (min-width: 992px) {
	.light-overlay-lg,
	.overlay-hover-light-lg {
		position: relative;
	}
	.light-overlay-lg::after,
	.overlay-hover-light-lg::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: ' ';
		background: #fff;
	}
}

@media (min-width: 992px) {
	.overlay-hover-light-lg::after {
		transition: opacity 0.15s linear;
		opacity: 0;
	}
}

@media (min-width: 992px) {
	.light-overlay-lg::after,
	.overlay-hover-light-lg:hover::after {
		opacity: 0.4;
	}
}

@media (min-width: 992px) {
	.light-overlay-lg-0::after,
	.overlay-hover-light-lg-0:hover::after {
		display: none;
	}
}

.dark-overlay-lg {
	position: relative;
}

@media (min-width: 992px) {
	.dark-overlay-lg::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: ' ';
		opacity: 0.4;
		background: #000;
	}
}

@media (min-width: 992px) {
	.dark-overlay-lg-0::after {
		display: none;
	}
}

@media (min-width: 1200px) {
	.light-overlay-xl,
	.overlay-hover-light-xl {
		position: relative;
	}
	.light-overlay-xl::after,
	.overlay-hover-light-xl::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: ' ';
		background: #fff;
	}
}

@media (min-width: 1200px) {
	.overlay-hover-light-xl::after {
		transition: opacity 0.15s linear;
		opacity: 0;
	}
}

@media (min-width: 1200px) {
	.light-overlay-xl::after,
	.overlay-hover-light-xl:hover::after {
		opacity: 0.4;
	}
}

@media (min-width: 1200px) {
	.light-overlay-xl-0::after,
	.overlay-hover-light-xl-0:hover::after {
		display: none;
	}
}

.dark-overlay-xl {
	position: relative;
}

@media (min-width: 1200px) {
	.dark-overlay-xl::after {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: ' ';
		opacity: 0.4;
		background: #000;
	}
}

@media (min-width: 1200px) {
	.dark-overlay-xl-0::after {
		display: none;
	}
}

.light-overlay,
.overlay-hover-light {
	position: relative;
}

.light-overlay::after,
.overlay-hover-light::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: ' ';
	background: #fff;
}

.overlay-hover-light::after {
	transition: opacity 0.15s linear;
	opacity: 0;
}

.light-overlay::after,
.overlay-hover-light:hover::after {
	opacity: 0.4;
}

.light-overlay-0::after,
.overlay-hover-light-0:hover::after {
	display: none;
}

.dark-overlay {
	position: relative;
}

.dark-overlay::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: ' ';
	opacity: 0.4;
	background: #000;
}

.dark-overlay-0::after {
	display: none;
}

/*
     Other
    ------------------------
*/
.overflow-visible {
	overflow: visible !important;
}

.shadow-0 {
	box-shadow: none !important;
}

.bg-cover {
	background-size: cover !important;
}

.bg-center {
	background-position: center center !important;
}

.reset-anchor {
	color: inherit;
	transition: all 0.3s;
}

.reset-anchor:hover,
.reset-anchor:focus {
	text-decoration: none;
}

.index-forward {
	z-index: 99;
	position: relative;
}

.no-select {
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/*
* ===================================================
*    Custom Orion Icons
* ===================================================
*/
.svg-icon {
	width: 32px;
	height: 32px;
	vertical-align: text-bottom;
	stroke: currentColor;
	stroke-width: 3;
	--layer1: currentColor;
	--layer2: currentColor;
	--layer3: currentColor;
}

.svg-icon.svg-icon-light {
	stroke-width: 2;
}

.svg-icon.svg-icon-heavy {
	stroke-width: 4;
}

.svg-icon-big {
	width: 48px;
	height: 48px;
}

.svg-icon-sm {
	width: 24px;
	height: 24px;
}

/*
*
* ==========================================
* OWL THUMBS
* ==========================================
*
*/
.owl-thumb-item {
	cursor: pointer;
	background: #111111;
}

.owl-thumb-item img {
	transition: all 0.3s;
}

.owl-thumb-item.active img {
	opacity: 0.8;
}

/*
*
* ==========================================
* NOUISLIDER
* ==========================================
*
*/
.noUi-target {
	background: #efefef;
	border-radius: 0;
	border: none;
	box-shadow: none;
}

.noUi-horizontal {
	height: 2px;
}

@media (max-width: 991.98px) {
	.noUi-horizontal {
		height: 3px;
	}
}

.noUi-horizontal .noUi-handle {
	width: 10px;
	height: 10px;
	right: -10px;
	top: -4px;
	outline: none;
	border-radius: 50%;
	background: #111111;
	border: none !important;
	box-shadow: none;
	cursor: pointer;
}

@media (max-width: 991.98px) {
	.noUi-horizontal .noUi-handle {
		top: -3px;
	}
}

.noUi-horizontal .noUi-handle::before,
.noUi-horizontal .noUi-handle::after {
	display: none;
}

.noUi-connect {
	background: #111111;
}

.noUi-tooltip {
	font-size: 0.75rem;
	background: #fff;
	border-radius: 0;
	border: none;
	color: #111111;
	padding: 0.2rem 0;
}

.noUi-handle-lower .noUi-tooltip {
	left: 0;
	-webkit-transform: none;
	transform: none;
}

.noUi-handle-upper .noUi-tooltip {
	left: auto;
	right: 0;
	-webkit-transform: none;
	transform: none;
}

/*
*
* ==========================================
* BOOTSTRAP SELECT
* ==========================================
*
*/
.bootstrap-select {
	display: block !important;
}

.bootstrap-select .bs-select-form-control.dropdown-toggle {
	display: block !important;
	border: 1px solid #ced4da !important;
	padding: 0.2rem 1rem !important;
	outline: none !important;
	box-shadow: none;
}

.bootstrap-select .bs-select-form-control.dropdown-toggle::after {
	margin-left: -0.5rem !important;
}

.bootstrap-select .dropdown-menu {
	padding: 0;
	border-radius: 0;
	border-color: #ddd;
	box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
	max-height: 200px !important;
	-webkit-transform: none !important;
	transform: none !important;
	margin: 0 !important;
	max-width: 100%;
}

.bootstrap-select .dropdown-menu .dropdown-item {
	padding: 0.7rem 1rem;
	transition: all 0.3s;
	outline: none !important;
	font-size: 0.85rem;
	-webkit-transform: none !important;
	transform: none !important;
	font-weight: 300;
	border-bottom: 1px solid #eee;
}

.bootstrap-select .dropdown-menu .dropdown-item.active,
.bootstrap-select .dropdown-menu .dropdown-item:active {
	background: #f8f9fa !important;
	color: #343a40 !important;
}

.bootstrap-select .filter-option-inner-inner {
	color: #adb5bd;
	font-size: 0.85rem;
	font-weight: 300;
	line-height: 1.5rem !important;
	text-transform: capitalize;
	-webkit-transform: translateY(1px);
	transform: translateY(1px);
	letter-spacing: 0;
}

.bootstrap-select .filter-option-inner-inner.selected {
	color: #111111;
	font-size: 0.95rem;
}

.bootstrap-select .bs-searchbox {
	padding: 0.5rem;
}

.bootstrap-select .bs-searchbox input {
	padding-left: 1rem;
	padding-right: 1rem;
}

.bootstrap-select .bs-searchbox input:focus {
	box-shadow: none;
	border: 1px solid #ddd;
}

.bootstrap-select .bs-searchbox input::-webkit-input-placeholder {
	font-weight: 300;
}

.bootstrap-select .bs-searchbox input::-moz-placeholder {
	font-weight: 300;
}

.bootstrap-select .bs-searchbox input:-ms-input-placeholder {
	font-weight: 300;
}

.bootstrap-select .bs-searchbox input::-ms-input-placeholder {
	font-weight: 300;
}

.bootstrap-select .bs-searchbox input::placeholder {
	font-weight: 300;
}

.bootstrap-select .no-results {
	margin: 0 0.5rem 0.5rem 0.5rem;
	padding: 0.2rem 1rem;
}

.bootstrap-select button.dropdown-toggle:active,
.bootstrap-select button.dropdown-toggle:focus {
	outline: none !important;
}

.bootstrap-select button.dropdown-toggle::after {
	border: none;
	content: '\f078' !important;
	font-weight: 900;
	font-size: 0.65rem;
	vertical-align: middle !important;
	padding-left: 0.1rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	position: absolute;
	right: 1rem;
	top: calc(50% + 2px);
}

.bootstrap-select button.dropdown-toggle.form-control-lg .filter-option {
	position: absolute;
	top: 50%;
	left: 1rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	height: 1.5rem;
	line-height: 1.5rem;
}

.bootstrap-select .dropdown-toggle[aria-expanded='true']::after {
	content: '\f077' !important;
}

/*

=====================
STYLE SWITCHER FOR DEMO
=====================

*/
#style-switch-button {
	position: fixed;
	top: 120px;
	left: 0px;
	border-radius: 0;
	z-index: 2;
}

#style-switch {
	width: 300px;
	padding: 20px;
	position: fixed;
	top: 160px;
	left: 0;
	background: #fff;
	border: solid 1px #ced4da;
	z-index: 2000;
}

#style-switch h4 {
	color: #495057;
}

/* =========================================

      THEMING OF THE BOOTSTRAP COMPONENTS

   =========================================

    1 - NAVBAR
    2 - BUTTONS
    3 - TYPE
    4 - PAGINATION
    5 - UTILITIES
    6 - FORMS
    7 - CODE
    8 - NAV
    9 - CARD
    10 - DROPDOWNS
    11 - MODALS
    12 - BREADCRUMB

*/
/*
 * 1. NAVBAR
 */
.navbar {
	padding: 0.5rem 1rem;
}

.navbar-brand {
	display: inline-block;
	padding-top: 0.3125rem;
	padding-bottom: 0.3125rem;
	margin-right: 1rem;
	font-size: 1.25rem;
}

.navbar-toggler {
	padding: 0.25rem 0.75rem;
	font-size: 1.25rem;
	line-height: 1;
	border: 1px solid transparent;
	border-radius: 0;
}

.navbar-light .navbar-brand {
	color: #dcb14a;
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
	color: #dcb14a;
}

.navbar-light .navbar-nav .nav-link {
	color: rgba(17, 17, 17, 0.99);
	font-weight: 800;
	font-size: 0.95rem;
	transition: all 0.3s;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: #dcb14a;
}

.navbar-light .navbar-nav .nav-link.disabled {
	color: rgba(17, 17, 17, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
	color: #dcb14a;
}

.navbar-light .navbar-toggler {
	color: rgba(17, 17, 17, 0.99);
	border-color: rgba(17, 17, 17, 0.1);
}

.navbar-light .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(17, 17, 17, 0.99)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
	color: rgba(17, 17, 17, 0.99);
}

.navbar-dark .navbar-brand {
	color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
	color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
	color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
	color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
	color: #fff;
}

.navbar-dark .navbar-toggler {
	color: rgba(255, 255, 255, 0.5);
	border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, 0.5);
}

/*
 * 2. BUTTONS
 */
.btn {
	font-weight: 400;
	border: 1px solid transparent;
	padding: 0.375rem 1.5rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
	.btn {
		transition: none;
	}
}

.btn:focus,
.btn.focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 177, 74, 0.25);
}

.btn.disabled,
.btn:disabled {
	opacity: 0.65;
}

.btn:not(:disabled):not(.disabled):active,
.btn:not(:disabled):not(.disabled).active {
	background-image: none;
}

.btn-link {
	font-weight: 400;
	color: #dcb14a;
}

.btn-link:hover {
	color: #b68b23;
	text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
	text-decoration: underline;
	box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
	color: #6c757d;
}

.btn-primary {
	color: #212529;
	background-color: #dcb14a;
	border-color: #dcb14a;
}

.btn-primary:hover {
	color: #212529;
	background-color: #d6a32a;
	border-color: #cc9b27;
}

.btn-primary:focus,
.btn-primary.focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 177, 74, 0.5);
	box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
	color: #212529;
	background-color: #dcb14a;
	border-color: #dcb14a;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
	color: #212529;
	background-color: #cc9b27;
	border-color: #c19325;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 177, 74, 0.5);
}

.btn-primary:focus {
	text-decoration: none;
}

.btn-secondary {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}

.btn-secondary:hover {
	color: #fff;
	background-color: #5a6268;
	border-color: #545b62;
}

.btn-secondary:focus,
.btn-secondary.focus {
	box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
	box-shadow: none;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
	color: #fff;
	background-color: #545b62;
	border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-secondary:focus {
	text-decoration: none;
}

.btn-success {
	color: #fff;
	background-color: #28a745;
	border-color: #28a745;
}

.btn-success:hover {
	color: #fff;
	background-color: #218838;
	border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
	box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
	box-shadow: none;
}

.btn-success.disabled,
.btn-success:disabled {
	color: #fff;
	background-color: #28a745;
	border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
	color: #fff;
	background-color: #1e7e34;
	border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success:focus {
	text-decoration: none;
}

.btn-info {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
}

.btn-info:hover {
	color: #fff;
	background-color: #138496;
	border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
	box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
	box-shadow: none;
}

.btn-info.disabled,
.btn-info:disabled {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
	color: #fff;
	background-color: #117a8b;
	border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-info:focus {
	text-decoration: none;
}

.btn-warning {
	color: #212529;
	background-color: #ffc107;
	border-color: #ffc107;
}

.btn-warning:hover {
	color: #212529;
	background-color: #e0a800;
	border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
	box-shadow: none;
}

.btn-warning.disabled,
.btn-warning:disabled {
	color: #212529;
	background-color: #ffc107;
	border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
	color: #212529;
	background-color: #d39e00;
	border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-warning:focus {
	text-decoration: none;
}

.btn-danger {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
}

.btn-danger:hover {
	color: #fff;
	background-color: #c82333;
	border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
	box-shadow: none;
}

.btn-danger.disabled,
.btn-danger:disabled {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
	color: #fff;
	background-color: #bd2130;
	border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-danger:focus {
	text-decoration: none;
}

.btn-light {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-light:hover {
	color: #212529;
	background-color: #e2e6ea;
	border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
	box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
	box-shadow: none;
}

.btn-light.disabled,
.btn-light:disabled {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
	color: #212529;
	background-color: #dae0e5;
	border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light:focus {
	text-decoration: none;
}

.btn-dark {
	color: #fff;
	background-color: #383838;
	border-color: #383838;
}

.btn-dark:hover {
	color: #fff;
	background-color: black;
	border-color: black;
}

.btn-dark:focus,
.btn-dark.focus {
	box-shadow: 0 0 0 0.2rem rgba(17, 17, 17, 0.5);
	box-shadow: none;
}

.btn-dark.disabled,
.btn-dark:disabled {
	color: #fff;
	background-color: #111111;
	border-color: #111111;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
	color: #fff;
	background-color: black;
	border-color: black;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
	box-shadow: 0 0 0 0.2rem rgba(17, 17, 17, 0.5);
}

.btn-dark:focus {
	text-decoration: none;
}

.btn-outline-primary {
	color: #dcb14a;
	background-color: transparent;
	background-image: none;
	border-color: #dcb14a;
}

.btn-outline-primary:hover {
	color: #fff;
	background-color: #dcb14a;
	border-color: #dcb14a;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 177, 74, 0.5);
	box-shadow: none;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
	color: #dcb14a;
	background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
	color: #212529;
	background-color: #dcb14a;
	border-color: #dcb14a;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-outline-primary:focus {
	color: #fff;
	background: #dcb14a;
	text-decoration: none;
}

.btn-outline-secondary {
	color: #6c757d;
	background-color: transparent;
	background-image: none;
	border-color: #6c757d;
}

.btn-outline-secondary:hover {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
	box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
	box-shadow: none;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
	color: #6c757d;
	background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-outline-secondary:focus {
	color: #fff;
	background: #6c757d;
	text-decoration: none;
}

.btn-outline-success {
	color: #28a745;
	background-color: transparent;
	background-image: none;
	border-color: #28a745;
}

.btn-outline-success:hover {
	color: #fff;
	background-color: #28a745;
	border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
	box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
	box-shadow: none;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
	color: #28a745;
	background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
	color: #fff;
	background-color: #28a745;
	border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-outline-success:focus {
	color: #fff;
	background: #28a745;
	text-decoration: none;
}

.btn-outline-info {
	color: #17a2b8;
	background-color: transparent;
	background-image: none;
	border-color: #17a2b8;
}

.btn-outline-info:hover {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
	box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
	box-shadow: none;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
	color: #17a2b8;
	background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
	color: #fff;
	background-color: #17a2b8;
	border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-outline-info:focus {
	color: #fff;
	background: #17a2b8;
	text-decoration: none;
}

.btn-outline-warning {
	color: #ffc107;
	background-color: transparent;
	background-image: none;
	border-color: #ffc107;
}

.btn-outline-warning:hover {
	color: #fff;
	background-color: #ffc107;
	border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
	box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
	box-shadow: none;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
	color: #ffc107;
	background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
	color: #212529;
	background-color: #ffc107;
	border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-outline-warning:focus {
	color: #fff;
	background: #ffc107;
	text-decoration: none;
}

.btn-outline-danger {
	color: #dc3545;
	background-color: transparent;
	background-image: none;
	border-color: #dc3545;
}

.btn-outline-danger:hover {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
	box-shadow: none;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
	color: #dc3545;
	background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
	color: #fff;
	background-color: #dc3545;
	border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-outline-danger:focus {
	color: #fff;
	background: #dc3545;
	text-decoration: none;
}

.btn-outline-light {
	color: #f8f9fa;
	background-color: transparent;
	background-image: none;
	border-color: #f8f9fa;
}

.btn-outline-light:hover {
	color: #fff;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
	box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
	box-shadow: none;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
	color: #f8f9fa;
	background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
	color: #212529;
	background-color: #f8f9fa;
	border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-outline-light:focus {
	color: #fff;
	background: #f8f9fa;
	text-decoration: none;
}

.btn-outline-dark {
	color: #111111;
	background-color: transparent;
	background-image: none;
	border-color: #111111;
}

.btn-outline-dark:hover {
	color: #fff;
	background-color: #111111;
	border-color: #111111;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
	box-shadow: 0 0 0 0.2rem rgba(17, 17, 17, 0.5);
	box-shadow: none;
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
	color: #111111;
	background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
	color: #fff;
	background-color: #111111;
	border-color: #111111;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
	box-shadow: none;
}

.btn-outline-dark:focus {
	color: #fff;
	background: #111111;
	text-decoration: none;
}

.btn-lg {
	padding: 0.5rem 1rem;
	font-size: 1.25rem;
	line-height: 1.5;
	border-radius: 0;
}

.btn-sm {
	padding: 0.375rem 0.75rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0;
}

/*
 * 3. TYPE
 */
body {
	font-family: 'Montserrat', sans-serif !important;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #111111;
	background-color: #fff !important;
}

a {
	color: #dcb14a;
	text-decoration: none;
}

a:hover,
a:focus {
	color: #b68b23;
	text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: 0.5rem;
	font-weight: 900;
	line-height: 1.2;
	color: inherit;
}

h1,
.h1 {
	font-size: 2.2rem;
}

h2,
.h2 {
	font-size: 1.9rem;
}

h3,
.h3 {
	font-size: 1.65rem;
}

h4,
.h4 {
	font-size: 1.4rem;
}

h5,
.h5 {
	font-size: 1.15rem;
}

h6,
.h6 {
	font-size: 1rem;
}

.lead {
	font-size: 1.25rem;
	font-weight: 300;
}

.display-1 {
	font-size: 6rem;
	font-weight: 300;
	line-height: 1.2;
}

.display-2 {
	font-size: 5.5rem;
	font-weight: 300;
	line-height: 1.2;
}

.display-3 {
	font-size: 4.5rem;
	font-weight: 300;
	line-height: 1.2;
}

.display-4 {
	font-size: 3.5rem;
	font-weight: 300;
	line-height: 1.2;
}

hr {
	border-top: 1px solid rgba(17, 17, 17, 0.1);
}

small,
.small {
	font-size: 80%;
	font-weight: 400;
}

mark,
.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}

.blockquote {
	padding: 0.5rem 1rem;
	margin-bottom: 2rem;
	font-size: 1.25rem;
	border-left: 5px solid #dcb14a;
}

.blockquote-footer {
	color: #6c757d;
}

.blockquote-footer::before {
	content: '\2014 \00A0';
}

.text-primary {
	color: #dcb14a !important;
}

a.text-primary:hover,
a.text-primary:focus {
	color: #cc9b27 !important;
}

/*
 * 4. PAGINATION
 */
.page-item:first-child .page-link {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.page-item:last-child .page-link {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.page-item.active .page-link {
	color: #fff;
	background-color: #111111;
	border-color: #111111;
}

.page-item:hover .page-link {
	color: #fff !important;
}

.page-item.disabled .page-link {
	color: #6c757d;
	background-color: #fff;
	border-color: #dee2e6;
}

.page-link {
	padding: 0.5rem 0.75rem;
	line-height: 1.25;
	color: #111111;
	background-color: none;
	border: 1px solid #eee;
	margin: 0.2rem;
	transition: all 0.3s;
}

.page-link:hover {
	z-index: 2;
	color: #111111;
	text-decoration: none;
	background-color: #e9ecef;
	border-color: #dee2e6;
}

.page-link:focus {
	z-index: 2;
	outline: 0;
	box-shadow: none;
	text-decoration: none;
	background-color: #111111;
	color: #fff;
}

.pagination-lg .page-link {
	padding: 0.75rem 1.5rem;
	font-size: 1.25rem;
	line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.pagination-lg .page-item:last-child .page-link {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.pagination-sm .page-item:last-child .page-link {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

/*
* 5. UTILITIES
*/
.bg-primary {
	background-color: #dcb14a !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
	background-color: #cc9b27 !important;
}

.bg-secondary {
	background-color: #6c757d !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
	background-color: #545b62 !important;
}

.bg-success {
	background-color: #28a745 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
	background-color: #1e7e34 !important;
}

.bg-info {
	background-color: #17a2b8 !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
	background-color: #117a8b !important;
}

.bg-warning {
	background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
	background-color: #d39e00 !important;
}

.bg-danger {
	background-color: #dc3545 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
	background-color: #bd2130 !important;
}

.bg-light {
	background-color: #f8f9fa !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
	background-color: #dae0e5 !important;
}

.bg-dark {
	background-color: #111111 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
	background-color: black !important;
}

.border-primary {
	border-color: #dcb14a !important;
}

.border-secondary {
	border-color: #6c757d !important;
}

.border-success {
	border-color: #28a745 !important;
}

.border-info {
	border-color: #17a2b8 !important;
}

.border-warning {
	border-color: #ffc107 !important;
}

.border-danger {
	border-color: #dc3545 !important;
}

.border-light {
	border-color: #f8f9fa !important;
}

.border-dark {
	border-color: #111111 !important;
}

.text-primary {
	color: #dcb14a !important;
}

a.text-primary:hover,
a.text-primary:focus {
	color: #cc9b27 !important;
}

.text-secondary {
	color: #6c757d !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
	color: #545b62 !important;
}

.text-success {
	color: #28a745 !important;
}

a.text-success:hover,
a.text-success:focus {
	color: #1e7e34 !important;
}

.text-info {
	color: #17a2b8 !important;
}

a.text-info:hover,
a.text-info:focus {
	color: #117a8b !important;
}

.text-warning {
	color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
	color: #d39e00 !important;
}

.text-danger {
	color: #dc3545 !important;
}

a.text-danger:hover,
a.text-danger:focus {
	color: #bd2130 !important;
}

.text-light {
	color: #f8f9fa !important;
}

a.text-light:hover,
a.text-light:focus {
	color: #dae0e5 !important;
}

.text-dark {
	color: #111111 !important;
}

a.text-dark:hover,
a.text-dark:focus {
	color: black !important;
}

.badge-primary {
	color: #212529;
	background-color: #dcb14a;
	text-transform: uppercase;
	letter-spacing: 0.07em;
	border-radius: 0;
	font-weight: 400;
	padding: 0.35rem 0.5rem;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
	color: #212529;
	text-decoration: none;
	background-color: #cc9b27;
}

.badge-secondary {
	color: #fff;
	background-color: #6c757d;
	text-transform: uppercase;
	letter-spacing: 0.07em;
	border-radius: 0;
	font-weight: 400;
	padding: 0.35rem 0.5rem;
}

.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #545b62;
}

.badge-success {
	color: #fff;
	background-color: #28a745;
	text-transform: uppercase;
	letter-spacing: 0.07em;
	border-radius: 0;
	font-weight: 400;
	padding: 0.35rem 0.5rem;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #1e7e34;
}

.badge-info {
	color: #fff;
	background-color: #17a2b8;
	text-transform: uppercase;
	letter-spacing: 0.07em;
	border-radius: 0;
	font-weight: 400;
	padding: 0.35rem 0.5rem;
}

.badge-info[href]:hover,
.badge-info[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #117a8b;
}

.badge-warning {
	color: #212529;
	background-color: #ffc107;
	text-transform: uppercase;
	letter-spacing: 0.07em;
	border-radius: 0;
	font-weight: 400;
	padding: 0.35rem 0.5rem;
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
	color: #212529;
	text-decoration: none;
	background-color: #d39e00;
}

.badge-danger {
	color: #fff;
	background-color: #dc3545;
	text-transform: uppercase;
	letter-spacing: 0.07em;
	border-radius: 0;
	font-weight: 400;
	padding: 0.35rem 0.5rem;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: #bd2130;
}

.badge-light {
	color: #212529;
	background-color: #f8f9fa;
	text-transform: uppercase;
	letter-spacing: 0.07em;
	border-radius: 0;
	font-weight: 400;
	padding: 0.35rem 0.5rem;
}

.badge-light[href]:hover,
.badge-light[href]:focus {
	color: #212529;
	text-decoration: none;
	background-color: #dae0e5;
}

.badge-dark {
	color: #fff;
	background-color: #111111;
	text-transform: uppercase;
	letter-spacing: 0.07em;
	border-radius: 0;
	font-weight: 400;
	padding: 0.35rem 0.5rem;
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
	color: #fff;
	text-decoration: none;
	background-color: black;
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(17, 17, 17, 0.075) !important;
}

.shadow {
	box-shadow: 0 0.5rem 1rem rgba(17, 17, 17, 0.15) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(17, 17, 17, 0.175) !important;
}

/*
  * 6. FORMS
  */
.form-control {
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	border: 1px solid #ced4da;
	border-radius: 0;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media screen and (prefers-reduced-motion: reduce) {
	.form-control {
		transition: none;
	}
}

.form-control::-ms-expand {
	background-color: transparent;
	border: 0;
}

.form-control:focus {
	color: #495057;
	background-color: #fff;
	border-color: #f1dfb5;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(220, 177, 74, 0.25);
	box-shadow: inset 0 0 1px #111111;
	border-color: #111111;
}

.form-control::-webkit-input-placeholder {
	color: #adb5bd;
	font-size: 0.85rem;
	font-weight: 400;
}

.form-control::-moz-placeholder {
	color: #adb5bd;
	font-size: 0.85rem;
	font-weight: 400;
}

.form-control:-ms-input-placeholder {
	color: #adb5bd;
	font-size: 0.85rem;
	font-weight: 400;
}

.form-control::-ms-input-placeholder {
	color: #adb5bd;
	font-size: 0.85rem;
	font-weight: 400;
}

.form-control::placeholder {
	color: #adb5bd;
	font-size: 0.85rem;
	font-weight: 400;
}

.form-control:disabled,
.form-control[readonly] {
	background-color: #e9ecef;
}

select.form-control:not([size]):not([multiple]) {
	height: calc(2.25rem + 2px);
}

select.form-control:focus::-ms-value {
	color: #495057;
	background-color: #fff;
}

.form-control-sm {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0;
}

select.form-control-sm:not([size]):not([multiple]) {
	height: calc(1.8125rem + 2px);
}

.form-control-lg {
	padding: 0.5rem 1rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: 0;
}

select.form-control-lg:not([size]):not([multiple]) {
	height: calc(2.875rem + 2px);
}

.valid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #28a745;
}

.valid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	line-height: 1;
	color: #fff;
	background-color: rgba(40, 167, 69, 0.8);
	border-radius: 0.2rem;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
	border-color: #28a745;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
	border-color: #28a745;
	box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
	color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
	color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
	background-color: #71dd8a;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated
	.custom-control-input:valid:checked
	~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
	background-color: #34ce57;
}

.was-validated
	.custom-control-input:valid:focus
	~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
	border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label::before,
.custom-file-input.is-valid ~ .custom-file-label::before {
	border-color: inherit;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
	display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
	box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 80%;
	color: #dc3545;
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: 0.5rem;
	margin-top: 0.1rem;
	font-size: 0.875rem;
	line-height: 1;
	color: #fff;
	background-color: rgba(220, 53, 69, 0.8);
	border-radius: 0.2rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
	border-color: #dc3545;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
	border-color: #dc3545;
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
	color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
	color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
	background-color: #efa2a9;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated
	.custom-control-input:invalid:checked
	~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
	background-color: #e4606d;
}

.was-validated
	.custom-control-input:invalid:focus
	~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
	border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label::before,
.custom-file-input.is-invalid ~ .custom-file-label::before {
	border-color: inherit;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
	display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
	box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	background-color: #dcb14a;
}

.custom-control-input:focus ~ .custom-control-label::before {
	box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 177, 74, 0.25);
}

.custom-control-input:active ~ .custom-control-label::before {
	color: #fff;
	background-color: #f9f1e0;
}

.custom-control-input:disabled ~ .custom-control-label {
	color: #6c757d;
}

.custom-control-input:disabled ~ .custom-control-label::before {
	background-color: #e9ecef;
}

.custom-checkbox .custom-control-label::before {
	border-radius: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #dcb14a;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.custom-checkbox
	.custom-control-input:indeterminate
	~ .custom-control-label::before {
	background-color: #dcb14a;
}

.custom-checkbox
	.custom-control-input:indeterminate
	~ .custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-checkbox
	.custom-control-input:disabled:checked
	~ .custom-control-label::before {
	background-color: rgba(220, 177, 74, 0.5);
}

.custom-checkbox
	.custom-control-input:disabled:indeterminate
	~ .custom-control-label::before {
	background-color: rgba(220, 177, 74, 0.5);
}

.custom-radio .custom-control-label::before {
	border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #dcb14a;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-radio
	.custom-control-input:disabled:checked
	~ .custom-control-label::before {
	background-color: rgba(220, 177, 74, 0.5);
}

.custom-control-label {
	font-weight: 400;
	line-height: 2.1em;
	cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label::before {
	background: #dcb14a;
	border-color: #dcb14a;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
	border-color: rgba(220, 177, 74, 0.5);
}

/*
* 7.CODE
*/
code {
	font-size: 87.5%;
	color: #e83e8c;
}

/*
* 8. NAV
*/
.nav-link {
	padding: 0.5rem 1rem;
}

.nav-link.disabled {
	color: #6c757d;
}

.nav-tabs .nav-item {
	margin-bottom: -1px;
}

.nav-tabs .nav-link {
	border: 1px solid transparent;
	color: #111111;
	text-transform: uppercase;
	font-size: 0.8rem;
	transition: all 0.3s;
	font-weight: 700;
	padding: 0.7rem 1.5rem;
	background: #fff;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
	border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
	color: #6c757d;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover,
.nav-tabs .nav-item.show .nav-link {
	background: #111111;
	background-color: #111111;
	color: #fff;
}

.nav-tabs .dropdown-menu {
	margin-top: -1px;
}

.nav-pills .nav-link {
	border-radius: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	color: #fff;
	background-color: #dcb14a;
}

/*
* 9. CARD
*/
.card {
	background-color: #fff;
	border: 1px solid rgba(17, 17, 17, 0.125);
	border-radius: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.card > .list-group:last-child .list-group-item:last-child {
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}

.card-body {
	padding: 1.25rem;
}

.card-title {
	margin-bottom: 0.75rem;
}

.card-subtitle {
	margin-top: -0.375rem;
}

.card-link + .card-link {
	margin-left: 1.25rem;
}

.card-header {
	padding: 0.75rem 1.25rem;
	background-color: rgba(17, 17, 17, 0.03);
	border-bottom: 1px solid rgba(17, 17, 17, 0.125);
}

.card-header:first-child {
	border-radius: calc(0 - 1px) calc(0 - 1px) 0 0;
}

.card-header-transparent {
	background-color: rgba(0, 0, 0, 0.3);
	border-bottom: none;
}

.card-footer {
	padding: 0.75rem 1.25rem;
	background-color: #f8f9fa;
	border-top: 1px solid rgba(17, 17, 17, 0.125);
}

.card-footer:last-child {
	border-radius: 0 0 calc(0 - 1px) calc(0 - 1px);
}

.card-header-tabs {
	margin-right: -0.625rem;
	margin-bottom: -0.75rem;
	margin-left: -0.625rem;
	border-bottom: 0;
}

.card-header-pills {
	margin-right: -0.625rem;
	margin-left: -0.625rem;
}

.card-img-overlay {
	padding: 1.25rem;
}

.card-img-overlay-opacity {
	background: rgba(0, 0, 0, 0.2);
}

.card-img {
	border-radius: calc(0 - 1px);
}

.card-img-top {
	border-top-left-radius: calc(0 - 1px);
	border-top-right-radius: calc(0 - 1px);
}

.card-img-bottom {
	border-bottom-right-radius: calc(0 - 1px);
	border-bottom-left-radius: calc(0 - 1px);
}

.card-deck .card {
	margin-bottom: 15px;
}

@media (min-width: 576px) {
	.card-deck {
		margin-right: -15px;
		margin-left: -15px;
	}
	.card-deck .card {
		margin-right: 15px;
		margin-left: 15px;
	}
}

/*
* 10. DROPDOWNS
*/
.dropdown-menu {
	min-width: 12.5rem;
	padding: 0.5rem 0;
	margin: 0.125rem 0 0;
	font-size: 0.9rem;
	color: #111111;
	background-color: #fff;
	border: none;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
	border-radius: 0;
}

.dropdown-item {
	padding: 0.375rem 1.5rem;
	font-weight: 400;
	color: #212529;
	background: #fff;
	font-size: 0.9rem;
	transition: all 0.3s;
}

.dropdown-item:hover,
.dropdown-item:focus {
	color: #16181b;
	background-color: #f8f9fa;
}

.dropdown-item.active,
.dropdown-item:active {
	color: #fff;
	background-color: #dcb14a;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
	color: #6c757d;
}

/*
* 11. MODALS
*/
.modal-content {
	border-radius: 0;
}

@media (min-width: 992px) {
	.modal-lg {
		min-width: 900px;
	}
}

.modal-backdrop.show {
	opacity: 0.7;
}

/*
 * 12. BREADCRUMB
 */
.breadcrumb {
	background: #f8f9fa;
}

.breadcrumb-item {
	font-size: 0.85rem;
	text-transform: uppercase;
	letter-spacing: 0.07em;
	font-weight: 900;
	color: #111111;
}

.breadcrumb-item a {
	color: inherit;
	transition: all 0.3s;
}

.breadcrumb-item a:hover,
.breadcrumb-item a:focus {
	color: #dcb14a;
	text-decoration: none;
}

.breadcrumb-item.active {
	color: #aaa;
}
